import { withSentryReactRouterV6Routing } from "@sentry/react";
import { ErrorBoundary } from "react-error-boundary";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes, useMatch } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "styled-components";

import { Layout } from "components/layout/Layout";
import { FmsReportLayout } from "components/layout/fms-report/FmsReportLayout";
import { useUserPages } from "components/layout/layout.hooks";
import { addIconSet } from "components/ui-deprecated";
import { useFmsReportTokenValidity } from "hooks/fms-report/useFmsReportTokenValidity";
import { useEmbedder } from "hooks/useEmbedder";
import { useSendLocationChangeUserEvent } from "hooks/user-event/useSendLocationChangeUserEvent";
import { AdministrationPage, FmsReportPage } from "pages";
import { DashboardPage } from "pages/DashboardPage/DashboardPage";
import { ErrorPage } from "pages/ErrorPage/ErrorPage";
import { LoginPage } from "pages/LoginPage";
import { NotFoundPage } from "pages/NotFoundPage/NotFoundPage";
import { WelcomePage } from "pages/WelcomePage/WelcomePage";
import { fmsReportRoute, routes } from "routes";
import { initializeSentry } from "services/sentry";
import { persistor, store, useAppSelector } from "store";
import { useGetUserQuery } from "store/services/user.service";
import { GlobalStyles } from "styles/global.style";
import { theme } from "styles/theme";

import "i18n/i18n";

import "@datamole/wds-css-theme-lely/lely.theme.css";
// import "@datamole/wds-css-normalize/normalize.module.css"; // breaks some of the other styles
import "styles/customStyles.less";
import "styles/LelyIcons.css";

addIconSet("lely-icon");

initializeSentry();

const RoutesWithSentry = withSentryReactRouterV6Routing(Routes);

const LoadedAuthenticatedApp = () => {
    const userPages = useUserPages();

    useSendLocationChangeUserEvent();
    useEmbedder();

    return (
        <RoutesWithSentry>
            <Route element={<Layout />}>
                {userPages.map(({ path, Component }) => (
                    <Route key={path} path={path} element={<Component />} />
                ))}
                <Route path={routes.administration.path} element={<AdministrationPage />} />
                {/* Fallback page for the moment */}
                <Route path="" element={<DashboardPage />} />
                <Route path={"*"} element={<NotFoundPage />} />
            </Route>
        </RoutesWithSentry>
    );
};

const AuthenticatedApp = () => {
    const { isLoading } = useGetUserQuery();

    if (isLoading) {
        return <WelcomePage />;
    }

    return <LoadedAuthenticatedApp />;
};

const FmsReportApp = ({ token }: { token: string }) => {
    const isTokenValid = useFmsReportTokenValidity(token);

    useEmbedder();

    return (
        <RoutesWithSentry>
            <Route element={<FmsReportLayout isTokenValid={isTokenValid} />}>
                <Route path={fmsReportRoute.path} element={<FmsReportPage token={token} />} />
            </Route>
        </RoutesWithSentry>
    );
};

const App = () => {
    const farmScanReportRouteMatch = useMatch(fmsReportRoute.path);

    const isAuthenticated = useAppSelector((state) => {
        return state.auth.isAuthenticated;
    });

    if (farmScanReportRouteMatch) {
        return <FmsReportApp token={farmScanReportRouteMatch.params.token} />;
    }

    if (!isAuthenticated) return <LoginPage />;

    return <AuthenticatedApp />;
};

const AppWithProviders = () => {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ThemeProvider theme={theme}>
                    <ErrorBoundary fallback={<ErrorPage />}>
                        <BrowserRouter>
                            <GlobalStyles />
                            <App />
                        </BrowserRouter>
                    </ErrorBoundary>
                </ThemeProvider>
            </PersistGate>
        </Provider>
    );
};

export { AppWithProviders as App };
