import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import cs from "i18n/cs/resource.json";
import da from "i18n/da/resource.json";
import de from "i18n/de/resource.json";
import en from "i18n/en/resource.json";
import es from "i18n/es/resource.json";
import et from "i18n/et/resource.json";
import fi from "i18n/fi/resource.json";
import fr from "i18n/fr/resource.json";
import hu from "i18n/hu/resource.json";
import it from "i18n/it/resource.json";
import ja from "i18n/ja/resource.json";
import ko from "i18n/ko/resource.json";
import lt from "i18n/lt/resource.json";
import lv from "i18n/lv/resource.json";
import nl from "i18n/nl/resource.json";
import no from "i18n/no/resource.json";
import pl from "i18n/pl/resource.json";
import pt from "i18n/pt/resource.json";
import ru from "i18n/ru/resource.json";
import sv from "i18n/sv/resource.json";
import zh from "i18n/zh/resource.json";

const resources = {
    cs: {
        translation: cs,
    },
    da: {
        translation: da,
    },
    de: {
        translation: de,
    },
    en: {
        translation: en,
    },
    es: {
        translation: es,
    },
    et: {
        translation: et,
    },
    fi: {
        translation: fi,
    },
    fr: {
        translation: fr,
    },
    hu: {
        translation: hu,
    },
    it: {
        translation: it,
    },
    ja: {
        translation: ja,
    },
    ko: {
        translation: ko,
    },
    lt: {
        translation: lt,
    },
    lv: {
        translation: lv,
    },
    nl: {
        translation: nl,
    },
    no: {
        translation: no,
    },
    pl: {
        translation: pl,
    },
    pt: {
        translation: pt,
    },
    ru: {
        translation: ru,
    },
    sv: {
        translation: sv,
    },
    zh: {
        translation: zh,
    },
} as const;
export const defaultNS = "translation";
i18next.use(initReactI18next).init({
    resources,
    lng: "en",
    keySeparator: false,
    // we do not use keys in form messages.welcome
    interpolation: {
        escapeValue: false, // react already safes from xss
    },
});
export default i18next;
export { resources as i18nResources };
export type TranslationKey = keyof typeof en;
